import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import FeaturedResourcesModule from '../../components/featured-resources-module';
import SearchForm from '../../components/navigation/search-form';
import ResourceCard from '../../components/related-resources/resource-card';
import FeaturedResource from './featured-resource';
import PopularResources from './popular-resources';
import CategoriesDropdown from './categories-dropdown';
import './index.scss';

//TODO filter by category
export default function ResourcesPage ({ path, data, pageContext, location: navLocation }) {
  const {
    title, metaDescription, featuredResource, popularResourcesHeading,
    popularResourcesItemCta, popularResources, featuredResources, modules
  } = data.contentfulResourcesPage;

  data.contentfulSearchPage.path = `/${data.contentfulSearchPage.slug}`;

  data.allContentfulEvent?.nodes?.forEach((n) => {
    n.startDateDate = new Date(n.startDate || 0);

    if (n.startDateDate > Date.now()) {
      n.publishDateDate = new Date(Date.now() * 2 - n.startDateDate);
    } else {
      n.publishDateDate = n.startDateDate;
    }
  });

  data.allContentfulResource?.nodes?.forEach((n) => {
    n.publishDateDate = new Date(n.publishDate || 0);
  });

  let resourceCardsRendered;

  if (pageContext.category === 'Event') {
    resourceCardsRendered = data.allContentfulEvent.nodes.sort((a, b) => {
      if (a.prioritySort && !b.prioritySort) return -1;
      if (!a.prioritySort && b.prioritySort) return 1;
      if (a.startDateDate > Date.now() && b.startDateDate < Date.now()) return -1;
      if (a.startDateDate < Date.now() && b.startDateDate > Date.now()) return 1;
      if (a.startDateDate > Date.now()) return a.startDateDate - b.startDateDate;
      return b.startDateDate - a.startDateDate;
    }).map((r, i) => {
      return (<ResourceCard resource={r} key={i} />);
    });
  } else if (pageContext.category) {
    resourceCardsRendered = data.allContentfulResource.nodes.sort((a, b) => {
      if (a.prioritySort && !b.prioritySort) return -1;
      if (!a.prioritySort && b.prioritySort) return 1;
      return b.publishDateDate - a.publishDateDate;
    }).map((r, i) => {
      return (<ResourceCard resource={r} key={i} />);
    });
  } else {
    resourceCardsRendered = data.allContentfulResource.nodes
      .concat(data.allContentfulEvent.nodes)
      .sort((a, b) => {
        if (a.prioritySort && !b.prioritySort) return -1;
        if (!a.prioritySort && b.prioritySort) return 1;
        return b.publishDateDate - a.publishDateDate;
      }).map((r, i) => {
        return (<ResourceCard resource={r} key={i} />);
      });
  }

  const topSections = pageContext.category ? null : (
    <>
      <div className="featured-and-popular">
        <div className="container featured-and-popular__inner">
          <FeaturedResource {...featuredResource} />

          <PopularResources
            popularResourcesHeading={popularResourcesHeading}
            popularResourcesItemCta={popularResourcesItemCta}
            popularResources={popularResources}
          />
        </div>
      </div>

      <FeaturedResourcesModule {...featuredResources} heading={null} />
    </>
  );

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={featuredResource?.heroBackgroundImage?.fixed?.src} />
      </Helmet>

      <header className="resources-page">
        <div className="container">
          <h2>{title?.title}</h2>
        </div>
      </header>

      {topSections}

      <section className="resources-listing">
        <CategoriesDropdown pageContext={pageContext} navLocation={navLocation} />
        <nav className="resources-categories container">
          <ul>
            <li>
              <Link
                to={`/${pageContext.slug}/`}
                activeClassName="active"
              >
                All
              </Link>
            </li>
            <li>
              <Link
                to={`/${pageContext.slug}/articles/`}
                activeClassName="active"
              >
                Articles
              </Link>
            </li>
            <li>
              <Link
                to={`/${pageContext.slug}/case-studies/`}
                activeClassName="active"
              >
                Case Studies
              </Link>
            </li>
            <li>
              <Link
                to={`/${pageContext.slug}/downloads/`}
                activeClassName="active"
              >
                Downloads
              </Link>
            </li>
            <li>
              <Link
                to={`/${pageContext.slug}/news/`}
                activeClassName="active"
              >
                News
              </Link>
            </li>
            <li>
              <Link
                to={`/${pageContext.slug}/events/`}
                activeClassName="active"
              >
                Events
              </Link>
            </li>
          </ul>

          <SearchForm
            searchPage={data.contentfulSearchPage}
            autoFocus={false}
          />
        </nav>

        <div className="resources-resource-cards resource-cards">
          <div className="container">
            <div className="resources-resource-cards__inner">
              {resourceCardsRendered}
            </div>
          </div>
        </div>
      </section>

      {renderModules(modules)}

    </Layout>
  );
}

export const pageQuery = graphql`
query ResourcesPageById($id: String!, $category: String) {
  contentfulResourcesPage(id: {eq: $id}) {
    title { title }
    metaDescription { metaDescription }

    featuredResource {
      ...featuredResourceFields
    }

    popularResourcesHeading, popularResourcesItemCta
    popularResources {
      ...popularResourcesFields
    }

    featuredResources {
      ...featuredResourcesModuleFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }

  contentfulSearchPage {
    id, slug
  }

  allContentfulResource(
    filter: {category: {eq: $category}}
    sort: {fields: [updatedAt], order: [DESC]}
  ) {
    nodes {
      ...resourceCardFields
      prioritySort
      publishDate
    }
  }

  allContentfulEvent(
    sort: {fields: [updatedAt], order: [DESC]}
  ) {
    nodes {
      ...resourceEventCardFields
      prioritySort
      startDate
    }
  }
}
`;
