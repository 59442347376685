import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { navigate } from 'gatsby';

export default function CategoriesDropdown ({ pageContext, navLocation }) {
  const [ selectedCategory, setSelectedCategory ] = useState(navLocation.pathname);

  const handleChange = (ev) => {
    setSelectedCategory(ev.target.value);
    navigate(ev.target.value);
  };

  return (
    <div className="categories-dropdown">
      <div className="categories-dropdown__inner container">
        <FormControl variant="outlined">
          <InputLabel id="categories-dropdown-label">Category</InputLabel>
          <Select
            labelId="categories-dropdown-label"
            id="categories-dropdown"
            value={selectedCategory}
            onChange={handleChange}
            label="category"
          >
            <MenuItem value={`/${pageContext.slug}/`}>All</MenuItem>
            <MenuItem value={`/${pageContext.slug}/articles/`}>Articles</MenuItem>
            <MenuItem value={`/${pageContext.slug}/case-studies/`}>Case Studies</MenuItem>
            <MenuItem value={`/${pageContext.slug}/downloads/`}>Downloads</MenuItem>
            <MenuItem value={`/${pageContext.slug}/news/`}>News</MenuItem>
            <MenuItem value={`/${pageContext.slug}/events/`}>Events</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
