import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import plusIcon from '../../images/icon-plus.svg';
import { NavigationContext } from '../../components/navigation';
import './featured-resource.scss';

export default function FeaturedResource ({
  id, title, category, heroSubtitle, heroBackgroundImage
}) {
  const navigationMap = useContext(NavigationContext);

  const href = navigationMap.get(id)?.path ?? '#';

  return (
    <section className="resources-featured-resource">
      <Link to={href}>
        <div
          className="color-wash-background"
          style={{ background: `center / cover no-repeat url(${heroBackgroundImage?.fixed?.src})` }}
        ></div>
        <p className="card-washed-p">{category}</p>
        <h3>{title?.title}</h3>
        <p className="resources-featured-subtitle">{heroSubtitle?.heroSubtitle}</p>
        <img src={plusIcon} alt="" className="plus-icon" role="presentation" />
      </Link>
    </section>
  );
}

export const query = graphql`
fragment featuredResourceFields on ContentfulResource {
  id, category
  title { title }
  heroSubtitle { heroSubtitle }

  heroBackgroundImage {
    fixed(width: 960, toFormat: JPG, quality: 60) { src }
  }
}
`;
