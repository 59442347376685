import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import { NavigationContext } from '../../components/navigation';
import './popular-resources.scss';

export default function PopularResources ({
  popularResourcesHeading, popularResourcesItemCta, popularResources
}) {
  const navigationMap = useContext(NavigationContext);

  const popularResourcesRendered = popularResources?.map((r, i) => {
    const href = navigationMap.get(r.id)?.path ?? '#';

    return (
      <li key={i}>
        <Link to={href}>
          <span className="number">{i+1}</span>
          <span className="popular-resource-title">{r.shortTitle}</span>
          <span className="popular-resource-read-more" role="presentation">
            {popularResourcesItemCta}
          </span>
        </Link>
      </li>
    );
  });

  return (
    <section className="resources-popular-resource">
      <h5>{popularResourcesHeading}</h5>
      <ol>{popularResourcesRendered}</ol>
    </section>
  );
}

export const query = graphql`
fragment popularResourcesFields on ContentfulResource {
  id, shortTitle
}
`;
